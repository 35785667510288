// Import our CSS
import styles from "../css/app.pcss";
import Swiper from "swiper/swiper-bundle";
import "swiper/swiper-bundle.css";

// App main
const main = async () => {
	await import("lazysizes/plugins/bgset/ls.bgset");
};

// Execute async function
main().then(() => {
	// Helpers
	var parseHTML = function (str) {
		const tmp = document.implementation.createHTMLDocument("tmp");
		tmp.body.innerHTML = str;
		return tmp.body.children;
	};

	var _si = function (selector, context = document, returnNull = false) {
		const tmp = context.querySelector(selector);
		return tmp ? tmp : returnNull ? null : _c("div");
	};
	var _s = function (selector, context = document) {
		return Array.apply(null, context.querySelectorAll(selector));
	};

	var _c = function (elm) {
		return document.createElement(elm);
	};

	var _ael = function (sel, ev, callback) {
		document.addEventListener(ev, (e) => {
			if (e.target === document) {
				return;
			}
			const that = e.target.closest(sel);
			if (that) {
				callback(that, e);
			}
		});
	};

	// END HELPERS
	const html = _si("html");
	const body = document.body;
	let navOpen = false;
	var rect = _si("header").offsetHeight;
	var slideWidth = _si(".slide").offsetWidth;
	var slideAntall = _s(".slide").length;
	var sW = slideWidth * slideAntall;
	var finalTranslate = sW - slideWidth;
	var calcScroll = rect + sW - window.innerHeight;
	let translate = 0;
	const forside = _s(".forside-wrapper");
	const kontakt = _s(".kontakt");
	var path = _si(".path");
	if (forside.length || kontakt.length) {
		var length = path.getTotalLength();
	}

	_ael("#js--toggle-menu", "click", (elm, e) => {
		e.preventDefault();
		if (!navOpen) {
			navOpen = true;
			body.classList.add("nav-open");
		} else {
			navOpen = false;
			body.classList.remove("nav-open");
		}
	});

	if (window.innerWidth > 639) {
		_si(".forside-wrapper").style.height = sW + "px";
	}
	onscroll = (event) => {
		translate = window.scrollY - rect;
		if (window.innerWidth > 639) {
			if (window.scrollY < rect) {
				_si(".js--horscroll").classList.add("lg:absolute", "lg:top-0");
				_si(".js--horscroll").classList.remove(
					"lg:fixed",
					"lg:bottom-0"
				);
				_si(".slide-wrapper").style.transform = "translate(0px)";
			} else if (window.scrollY >= rect && window.scrollY < calcScroll) {
				_si(".js--horscroll").classList.add("lg:fixed", "lg:top-0");
				_si(".js--horscroll").classList.remove(
					"lg:absolute",
					"lg:bottom-0"
				);
				_si(".slide-wrapper").style.transform =
					"translate(-" + translate + "px)";
			} else if (window.scrollY >= calcScroll) {
				_si(".js--horscroll").classList.add(
					"lg:absolute",
					"lg:bottom-0"
				);
				_si(".js--horscroll").classList.remove("lg:fixed", "lg:top-0");
				_si(".slide-wrapper").style.transform =
					"translate(-" + finalTranslate + "px)";
			}
		}
	};
	const myCustomSlider = document.querySelectorAll(".referanse-swiper");

	for (var i = 0; i < myCustomSlider.length; i++) {
		myCustomSlider[i].classList.add("referanse-swiper-" + i);
		var slider = new Swiper(".referanse-swiper-" + i, {
			speed: 400,
			spaceBetween: 30,
			centeredSlides: true,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			breakpoints: {
				640: {
					slidesPerView: 1,
				},
				768: {
					slidesPerView: 2,
				},
				1280: {
					slidesPerView: 3,
				},
			},
		});
	}

	window.addEventListener("load", function () {
		body.style.opacity = "1";
		body.classList.add("loaded");
		if (forside || kontakt) {
			path.style.transition = path.style.WebkitTransition = "none";
			path.style.strokeDasharray = length + " " + length;
			path.style.strokeDashoffset = length;
			path.getBoundingClientRect();
			setTimeout(function () {
				path.style.transition = path.style.WebkitTransition =
					"stroke-dashoffset 3s ease-in-out";
				path.style.strokeDashoffset = "0";
			}, 500);
		}
	});
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}
